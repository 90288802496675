<template>
  <div>
    <tabs :tabList="tabList" @active-tab="activeTabName = $event" />

    <keep-alive>
      <component :is="`Dashboard${activeTabName}`" />
    </keep-alive>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs'
import DashboardReferralUsers from './DashboardReferralUsers'
import DashboardReferralAmount from './DashboardReferralAmount'

export default {
  data() {
    return {
      activeTabName: 'ReferralUsers',
      tabList: [
        { id: 1, name: 'ReferralUsers', title: 'referral users' },
        { id: 2, name: 'ReferralAmount', title: 'referral amount' }
      ]
    }
  },

  components: {
    Tabs,
    DashboardReferralUsers,
    DashboardReferralAmount
  }
}
</script>
